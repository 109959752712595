<template>
  <static-fullscreen-card>
    <template v-slot:title>({{ data.id }}) {{ data.name }}</template>
    <template v-slot:actions>
      <a-btn-edit v-if="getAccess('investor.edit', { users: [data.createdby_id] })" @click="showEditDialog = true" />
    </template>
    <portal to="v-main">
      <edit-dialog v-model="showEditDialog" :id="id"></edit-dialog>
    </portal>
    <v-row>
      <v-col cols="12" sm="6">
        <material-card color="secondary">
          <template v-slot:heading>
            <div class="px-3 py-1">информация</div>
          </template>
          <a-form-view v-model="data" :model="[model[1], model[6], model[7]]" :config="{ dense: true }" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="6">
        <material-card color="secondary">
          <template v-slot:heading>
            <div class="px-3 py-1">контакты</div>
          </template>

          <a-form-view :value="data" :model="[model[2], model[3], model[4], model[5]]" :config="{ dense: true }" class="block_info" />
        </material-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <material-card color="third" class="block_detail">
          <template v-slot:heading>
            <v-tabs v-model="tab" background-color="transparent" slider-color="white">
              <v-tab v-for="(tab, i) in tabs" :key="i" class="mr-3">{{ tab.title }}</v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="tab" class="transparent" style="min-height: 200px" touchless>
            <v-tab-item>
              <v-card-text :style="`height: ${detailHeight}px; overflow: auto`" class="pa-0 pt-0">
                <a-table-f-api
                  ref="table1"
                  api="/mechti/objects"
                  :model="$store.getters['config/get'].models.objects.list"
                  :searchable="false"
                  :defaults="{
                    filters: { investor_id: id },
                    sort: { key: 'id', order: 'DESC' },
                  }"
                  @click="
                    $router.push({
                      name: 'objects_view',
                      params: { id: $event.row.id },
                    })
                  "
                />
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text :style="`height: ${detailHeight}px; overflow: auto`" class="px-2">
                <a-view-files :value="data.docs" />
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text :style="`height: ${detailHeight}px; overflow: auto`" class="pa-0 pt-0">
                <a-table-f-api
                  ref="table3"
                  api="/caller/calls"
                  :model="callModel"
                  :searchable="false"
                  :defaults="{
                    filters: { phone: [data.phone1, data.phone2] },
                    sort: { key: 'id', order: 'DESC' },
                  }"
                />
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </material-card>
      </v-col>
    </v-row>
  </static-fullscreen-card>
</template>

<script>
import { getAccess, autoHeightBlock } from "@/components/mixings";
export default {
  mixins: [getAccess, autoHeightBlock],

  components: {
    editDialog: () => import("./../dialogs/investorDialog"),
  },
  data() {
    return {
      id: 0,
      showEditDialog: false,
      title: "",
      data: {},
      model: this.$store.getters["config/get"].models.investors.form,
      url: "/mechti/investors",
      tab: 0,
      tabs: [{ title: "Объекты" }, { title: "Документы" }, { title: "Разговоры" }],
      callModel: [
        {
          name: "calldate",
          type: "datetime",
          title: "Время",
          width: 130,
        },
        {
          name: "status",
          type: "select",
          options: this.$store.getters["config/get"].enums.callStatuses,
          title: "Статус",
          sortable: true,
          width: 100,
        },
        {
          name: "destination",
          type: "select",
          options: this.$store.getters["config/get"].enums.destinations,
          title: "Направление",
          sortable: true,
          width: 100,
        },
        {
          name: "manager_name",
          type: "string",
          filter: {
            type: "selectapi",
            name: "users.id",
            api: "/manager/users/select",
          },
          title: "Менеджер",
          sortable: true,
          width: 150,
        },
        {
          name: "billsec",
          type: "string",
          title: "Время разговора",
          sortable: true,
          width: 120,
        },
        {
          name: "recordingfile",
          type: "record",
          title: "Запись разговора",
          sortable: false,
          width: 350,
        },
      ],
    };
  },
  created() {
    this.$root.title = "Инвесторы";
    this.id = Number(this.$route.params.id);
    this.fitchData();
  },

  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.fitchData();
      }
    },
  },
  methods: {
    async fitchData() {
      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
      }
    },
  },
};
</script>
